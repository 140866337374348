.services-wrapper {
    display: flex;
    flex-direction: column;

    .services-header {
        background-color: #121212;
        padding: 2.43vw 0.69vw;
        display: flex;
        justify-content: center;
        gap: 2.08vw;

        .services-header-title-container {
            display: flex;
            flex-direction: column;
            transition: all ease 0.3s;
            color: #fce500;

            &::after {
                content: "";
                width: 0;
                height: 0.14vw;
                background: #fce500;
                transition: all ease 0.3s;
            }

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            .services-header-title {
                cursor: pointer;
                font-weight: 400;
                font-size: 1.11vw;
                line-height: 1.46vw;
                color: #fce500;
            }
        }
    }

    .services-offers-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 5.9vw;

        .services-offer-list {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 85vw;
            padding: 5.9vw 1.04vw 0 1.04vw;
            gap: 5.56vw;

            .services-offer-title {
                font-size: 2.5vw;
                font-weight: 700;
            }

            .services-offer-items-container {
                display: grid;
                grid-template-columns: repeat(3, 25vw);
                gap: 2.78vw;

                .services-offer-item-container {
                    padding: 2.29vw 3.4vw;
                    background-color: var(--main-light);
                    border: 0.07vw solid rgba(18, 18, 18, 0.2);
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    transition: all 0.4s ease;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 13.89vw;
                        object-fit: contain;
                        margin-bottom: 2.08vw;
                    }

                    .services-offer-item-title {
                        font-size: 1.25vw;
                        font-weight: 700;
                        margin-bottom: 1.6vw;
                        min-height: 2.78vw;
                    }

                    .services-offer-item-text {
                        color: #565656;
                        padding-bottom: 0.69vw;
                        font-size: 1.25vw;
                    }

                    .services-offer-item-action-container {
                        padding-top: 1.39vw;
                        border-top: 0.07vw solid rgba(18, 18, 18, 0.2);
                        display: flex;
                        align-items: center;
                        margin: auto 0 0;
                        justify-content: space-between;
                        gap: 0.69vw;

                        .services-offer-item-price {
                            font-size: 0.83vw;
                            font-weight: 300;

                            span {
                                font-size: 1.67vw;
                                color: #121212;
                            }
                        }

                        .button{
                            width: fit-content;
                            padding: 0.4vw 1.2vw;
                            p{
                                font-size: 1.25vw;
                            }
                        }
                    }
                }

                .services-offer-item-container:hover {
                    box-shadow: 0px 0px 2.08vw #dedede;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .services-wrapper {
        .services-header {
            padding: 3.65vw 1.04vw;
            gap: 3.12vw;

            .services-header-title-container {
                .services-header-title {
                    font-size: 1.67vw;
                    line-height: 2.19vw;
                }
            }
        }

        .services-offers-list {
            padding-bottom: 8.85vw;

            .services-offer-list {
                width: 90vw;
                padding: 8.85vw 1.56vw 0 1.56vw;
                gap: 8.34vw;

                .services-offer-title {
                    font-size: 3.75vw;
                }

                .services-offer-items-container {
                    grid-template-columns: repeat(auto-fit, minmax(27.29vw, 1fr));
                    gap: 4.17vw;

                    .services-offer-item-container {
                        padding: 3.44vw 5.1vw;

                        img {
                            height: 20.84vw;
                            margin-bottom: 3.12vw;
                        }

                        .services-offer-item-title {
                            font-size: 1.87vw;
                            margin-bottom: 2.4vw;
                        }

                        .services-offer-item-text {
                            font-size: 1.87vw;
                            padding-bottom: 1.04vw;
                        }

                        .services-offer-item-action-container {
                            padding-top: 2.08vw;
                            gap: 1.04vw;

                            .services-offer-item-price {
                                font-size: 1.25vw;

                                span {
                                    font-size: 2.51vw;
                                }
                            }

                            .button{
                                width: fit-content;
                                padding: 0.6vw 1.8vw;
                                p{
                                    font-size: 1.87vw;
                                }
                            }
                        }
                    }

                    .services-offer-item-container:hover {
                        box-shadow: 0px 0px 3.12vw #dedede;
                    }
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .services-wrapper {
        .services-header {
            flex-wrap: wrap;
            padding: 7.3vw 2.08vw;
            gap: 6.25vw;

            .services-header-title-container {
                .services-header-title {
                    font-size: 3.33vw;
                    line-height: 4.38vw;
                }
            }
        }

        .services-offers-list {
            padding-bottom: 17.7vw;

            .services-offer-list {
                padding: 17.7vw 3.13vw 0 3.13vw;
                gap: 16.67vw;

                .services-offer-title {
                    font-size: 7.5vw;
                }

                .services-offer-items-container {
                    grid-template-columns: repeat(auto-fit, minmax(54.58vw, 1fr));
                    gap: 8.34vw;

                    .services-offer-item-container {
                        padding: 6.88vw 10.2vw;

                        img {
                            height: 41.67vw;
                            margin-bottom: 6.25vw;
                        }

                        .services-offer-item-title {
                            font-size: 3.75vw;
                            margin-bottom: 4.8vw;
                        }

                        .services-offer-item-text {
                            font-size: 3.75vw;
                            padding-bottom: 2.08vw;
                        }

                        .services-offer-item-action-container {
                            padding-top: 4.17vw;
                            gap: 2.08vw;

                            .services-offer-item-price {
                                font-size: 2.5vw;

                                span {
                                    font-size: 5.02vw;
                                }
                            }

                            .button{
                                width: fit-content;
                                padding: 1.2vw 3.6vw;
                                p{
                                    
                                    font-size: 3.75vw;
                                }
                            }
                        }
                    }

                    .services-offer-item-container:hover {
                        box-shadow: 0px 0px 6.25vw #dedede;
                    }
                }
            }
        }
    }
}
