.payment-item-container{
    display: flex;
    flex-direction: column;
    border: 1px solid #cdcdcd;
    width: calc(33% - 2vw);


    img{
        padding: 1.1vw 0;
    }

    .payment-item-text-container{
        display: flex;
        flex-direction: column;
        gap: 1vw;
        align-items: flex-start;
        justify-content: space-between;
        border-top: 1px solid #cdcdcd;
        padding: 2.2vw;
        height: 100%;

        .payment-item-title{
            font-size: 1.2vw;
            font-weight: 700;
        }
        .payment-item-text{
            font-size: 1vw;
            color: #8F8F8F;
        }

        .button{
            width: fit-content;
            p{
                font-size: 1.1vw;
            }
        }
    }
}

@media(max-width: 768px){

    .payment-item-container{
        width: calc(50% - 3vw);


        img{
            padding: 1.7vw 0;
        }

        .payment-item-text-container{
            gap: 1.5vw;
            padding: 3.3vw;

            .payment-item-title{
                font-size: 1.8vw;
            }
            .payment-item-text{
                font-size: 1.5vw;
                color: #8F8F8F;
            }

            .button{
                width: fit-content;
                p{
                    font-size: 2.7vw;
                }
            }
        }
    }

}

@media(max-width: 425px){
    .payment-item-container{
        width: calc(100%);


        img{
            padding: 3.3vw 0;
        }

        .payment-item-text-container{
            gap: 3vw;
            padding: 6.6vw;

            .payment-item-title{
                font-size: 3.6vw;
            }
            .payment-item-text{
                font-size: 3vw;
            }

            .button{
                p{
                    font-size: 3.3vw;
                }
            }
        }
    }
        
}