.services {
    background: #fbfbfb;
    padding: 8.33vw 0;

    .services-container {
        display: flex;
        flex-direction: column;

        .services-title {
            font-weight: 700;
            font-size: 2.5vw;
            text-align: center;
            margin-bottom: 5.56vw;
            text-transform: capitalize;
        }

        .services-slider-container {
            width: 100%;
            display: flex;
            position: relative;
            padding: 0 3.47vw;

            .services-slider-content {
                display: flex;
                gap: 1.74vw;
                width: 100%;
                overflow: hidden;
                padding: 2.08vw 0;

                .services-slider-item {
                    position: relative;
                    width: calc(25% - 7.29vw);
                    padding: 2.78vw;
                    background-color: #fff;
                    box-shadow: 0px 0.14vw 1.39vw rgba(187, 187, 187, 0.15);
                    transition: all 0.3s ease;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 1.39vw;

                    &:hover {
                        transform: scale(1.1);
                    }

                    .services-slider-item-img {
                        width: 4.17vw;
                        height: 4.17vw;
                        margin-bottom: 2.78vw;
                    }

                    .services-slider-item-title {
                        margin-bottom: 2.22vw;
                        font-size: 1.39vw;
                        line-height: 1.81vw;
                        text-align: center;
                        letter-spacing: -0.015em;
                    }

                    .services-slider-item-text {
                        margin: 0 auto 2.22vw;
                        font-weight: 300;
                        font-size: 1.11vw;
                        line-height: 160%;
                        text-align: center;
                        letter-spacing: -0.015em;
                    }

                    .button {
                        width: 11.11vw;
                        height: 3.33vw;
                        border-radius: 3.96vw;
                        padding: 0;

                        p {
                            font-size: 0.97vw;
                        }
                    }
                }
            }

            .services-slider-button {
                min-width: 2.78vw;
                min-height: 2.78vw;
                max-width: 2.78vw;
                max-height: 2.78vw;
                border-radius: 50%;
                border: 0.07vw solid #000;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 2;
                top: calc(50% - 2.78vw);
                left: 0;
                cursor: pointer;

                &:last-child {
                    left: calc(100% - 2.67vw);

                    .services-slider-button-arrow {
                        transform: rotate(45deg);
                    }
                }

                .services-slider-button-arrow {
                    border-top: 0.14vw solid #000;
                    border-right: 0.14vw solid #000;
                    width: 0.69vw;
                    height: 0.69vw;
                    transform: rotate(-135deg);
                }
            }
        }

        .services-button {
            width: 21.53vw;
            margin: 5.56vw auto 0;
        }
    }
}

@media (max-width: 768px) {
    .services {
        padding: 12.5vw 0;

        .services-container {
            .services-title {
                font-size: 3.75vw;
                margin-bottom: 8.34vw;
            }

            .services-slider-container {
                width: 100%;
                padding: 0 5.21vw;

                .services-slider-content {
                    gap: 2.6vw;
                    padding: 3.13vw 0;

                    .services-slider-item {
                        width: calc(50% - 9.38vw);
                        padding: 4.17vw;
                        border-radius: 2.08vw;

                        .services-slider-item-img {
                            width: 6.25vw;
                            height: 6.25vw;
                            margin-bottom: 4.17vw;
                        }

                        .services-slider-item-title {
                            margin-bottom: 3.33vw;
                            font-size: 2.08vw;
                            line-height: 2.5vw;
                        }

                        .services-slider-item-text {
                            margin-bottom: 3.33vw;
                            font-size: 1.67vw;
                            line-height: 2vw;
                        }

                        .button {
                            width: 16.67vw;
                            height: 5vw;

                            p {
                                font-size: 1.46vw;
                            }
                        }
                    }
                }

                .services-slider-button {
                    min-width: 4.17vw;
                    min-height: 4.17vw;
                    max-width: 4.17vw;
                    max-height: 4.17vw;
                    top: calc(50% - 4.17vw);

                    &:last-child {
                        left: calc(100% - 4vw);
                    }

                    .services-slider-button-arrow {
                        width: 1.04vw;
                        height: 1.04vw;
                    }
                }
            }

            .services-button {
                width: 32.08vw;
                margin: 8.34vw auto 0;
            }
        }
    }
}

@media (max-width: 425px) {
    .services {
        padding: 25vw 0;

        .services-container {
            .services-title {
                font-size: 7.5vw;
                margin-bottom: 16.68vw;
            }

            .services-slider-container {
                width: 100%;
                padding: 0 10.42vw;

                .services-slider-content {
                    gap: 5.21vw;
                    padding: 6.26vw 0;

                    .services-slider-item {
                        width: calc(100% - 10.42vw);
                        padding: 8.34vw;
                        border-radius: 4.17vw;

                        .services-slider-item-img {
                            width: 12.5vw;
                            height: 12.5vw;
                            margin-bottom: 8.34vw;
                        }

                        .services-slider-item-title {
                            margin-bottom: 6.67vw;
                            font-size: 4.17vw;
                            line-height: 5vw;
                        }

                        .services-slider-item-text {
                            margin-bottom: 6.67vw;
                            font-size: 3.33vw;
                            line-height: 4vw;
                        }

                        .button {
                            width: 33.34vw;
                            height: 10vw;

                            p {
                                font-size: 2.92vw;
                            }
                        }
                    }
                }

                .services-slider-button {
                    min-width: 8.34vw;
                    min-height: 8.34vw;
                    max-width: 8.34vw;
                    max-height: 8.34vw;
                    top: calc(50% - 8.34vw);

                    &:last-child {
                        left: calc(100% - 8vw);
                    }

                    .services-slider-button-arrow {
                        width: 2.08vw;
                        height: 2.08vw;
                    }
                }
            }

            .services-button {
                width: 64.17vw;
                margin: 16.68vw auto 0;
            }
        }
    }
}
