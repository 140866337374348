.reasons {
    background: #f2f2f2;
    padding: 5.56vw 0;

    .reasons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3.82vw;

        .reasons-title {
            font-size: 2.5vw;
            font-weight: 400;
            text-align: center;

            span {
                font-weight: 700;
            }
        }

        .reasons-content {
            max-width: 65.97vw;
            text-align: center;
            font-size: 1.67vw;
            font-weight: 400;
        }

        .reasons-item-container {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 1.74vw;

            .reasons-item {
                width: calc(20% - 1.74vw);
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 230px;

                img {
                    margin-bottom: 3.47vw;
                    width: 6.94vw;
                    height: 6.94vw;
                }

                .reason-item-title {
                    font-size: 1.25vw;
                    font-weight: 700;
                    margin-bottom: 1.74vw;
                    text-align: center;
                }

                .reason-item-text {
                    font-size: 1.11vw;
                    line-height: 1.25vw;
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .reasons {
        padding: 8.34vw 0;

        .reasons-container {
            gap: 5.73vw;

            .reasons-title {
                font-size: 3.75vw;
            }

            .reasons-content {
                font-size: 2.5vw;
            }

            .reasons-item-container {
                gap: 2.6vw;

                .reasons-item {
                    width: calc(33% - 2.6vw);

                    img {
                        margin-bottom: 5.21vw;
                        width: 10.42vw;
                        height: 10.42vw;
                    }

                    .reason-item-title {
                        font-size: 1.88vw;
                        margin-bottom: 2.6vw;
                    }

                    .reason-item-text {
                        font-size: 1.67vw;
                        line-height: 1.88vw;
                    }
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .reasons {
        padding: 16.68vw 0;

        .reasons-container {
            gap: 11.46vw;

            .reasons-title {
                font-size: 7.5vw;
            }

            .reasons-content {
                font-size: 5vw;
            }

            .reasons-item-container {
                gap: 5.21vw;

                .reasons-item {
                    width: calc(50% - 5.21vw);

                    img {
                        margin-bottom: 10.42vw;
                        width: 20.83vw;
                        height: 20.83vw;
                    }

                    .reason-item-title {
                        font-size: 3.75vw;
                        margin-bottom: 5.21vw;
                    }

                    .reason-item-text {
                        font-size: 3.33vw;
                        line-height: 3.75vw;
                    }
                }
            }
        }
    }
}
