.home-banner{
    background: #fbfbfb;
    
    .home-banner-container{
        display: flex;
        flex-direction: column;
        gap: 1.74vw;
        padding: 5.9vw 0;

        .home-banner-content-container{
            position: relative;
            display: flex;
            width: 100%;

            .home-banner-content{
                display: flex;
                flex-direction: column;
                gap: 3.47vw;
                position: absolute;
                z-index: 2;

                .button{
                    width: fit-content;
                }

                .home-banner-content-text{
                    width: 34.72vw;
                    display: flex;
                    flex-direction: column;

                    .home-banner-title{
                        font-size: 6.67vw;
                        line-height: 8.68vw;
                        text-transform: uppercase;
                        font-weight: 700;
                    }
    
                    .home-banner-subtitle{
                        font-size: 2.5vw;
                        font-weight: 300;
                    }
                }

            }

            .home-banner-background{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                img{
                    width: 53.47vw;
                    height: 38.89vw;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .home-banner{
        .home-banner-container{
            gap: 2.61vw;
            padding: 8.85vw 0;

            .home-banner-content-container{

                .home-banner-content{
                    gap: 5.21vw;

                    .home-banner-content-text{
                        width: 52.08vw;

                        .home-banner-title{
                            font-size: 10vw;
                            line-height: 13.02vw;
                        }

                        .home-banner-subtitle{
                            font-size: 3.75vw;
                        }
                    }

                }

                .home-banner-background{
                    img{
                        width: 80.21vw;
                        height: 58.33vw;
                    }
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .home-banner{
        .home-banner-container{
            gap: 5.21vw;
            padding: 17.7vw 0;

            .home-banner-content-container{

                .home-banner-content{
                    gap: 10.42vw;

                    .home-banner-content-text{
                        width: 100%;

                        .home-banner-title{
                            font-size: 20vw;
                            line-height: 26.04vw;
                        }

                        .home-banner-subtitle{
                            font-size: 7.5vw;
                        }
                    }

                }

                .home-banner-background{
                    height: 125.67vw;

                    img{
                        position: relative;
                        top: 70vw;
                        width: 90vw;
                    }
                }
            }
        }
    }
}
