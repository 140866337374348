.burger{
    display: flex;
    flex-direction: column;
    position: relative;

    .burger-header{
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
        cursor: pointer;

        &.active{
            .burger-header-item:first-child{
                transform: rotate(45deg);
                top: 0.75vw;
            }

            .burger-header-item:nth-child(2){
                opacity: 0;
            }

            .burger-header-item:last-child{
                transform: rotate(-45deg);
                top: -0.75vw;
            }
        }

        .burger-header-item{
            position: relative;
            height: 0.25vw;
            width: 3.5vw;
            background: #000;
            transition: all ease 0.4s;

            
        }
    }

    .burger-container{
        position: fixed;
        left: 0;
        z-index: 2;
        width: 100vw;
        height: 0;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.9);
        transition: all ease 0.4s;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2vw;

        &.active{
            height: 100vh;
        }

        .burger-container-links{
            display: flex;
            flex-direction: column;
            gap: 1vw;

            a {
                display: flex;
                flex-direction: column;
                text-decoration: none;
                font-weight: 400;
                font-size: 2.45vw;
                transition: all ease 0.3s;
                color: #000;

                &::after{
                    content: "";
                    width: 0;
                    height: 0.25vw;
                    background: #000;
                    transition: all ease 0.3s;

                }

                &:hover {
                    &::after{
                        width: 100%;
                    }
                }
            }
        }
    }


}

@media (max-width: 425px) {

    .burger{
        .burger-header{
            gap: 1vw;
    
            &.active{
                .burger-header-item:first-child{
                    top: 1.5vw;
                }
    
                .burger-header-item:last-child{
                    top: -1.5vw;
                }
            }
    
            .burger-header-item{
                height: 0.5vw;
                width: 7vw;                
            }
        }
    
        .burger-container{
            gap: 4vw;
    
            .burger-container-links{
                gap: 2vw;
    
                a {
                    font-size: 4.9vw;
    
                    &::after{
                        height: 0.5vw;
    
                    }
    
                    &:hover {
                        &::after{
                            width: 100%;
                        }
                    }
                }
            }
        }
    
    
    }

}