.textarea-wrapper {
    width: 100%;

    .textarea {
        outline: none;
        width: 100%;
        padding: 1.25vw 2.15vw;
        background: transparent;
        border: 0.07vw solid #505050;
        color: #fce500;
        font-size: 1.25vw;
        height: 9.72vw;
        resize: none;
    }

    .textarea::placeholder {
        font-size: 1.25vw;
    }

    .textarea-error {
        margin-bottom: 0.35vw;
    }

    .textarea-error-text {
        font-size: 1.11vw;
        color: red;
        padding-left: 0.83vw;
    }
}

@media (max-width: 768px) {
    .textarea-wrapper {
        .textarea {
            padding: 1.88vw 3.22vw;
            border: 0.1vw solid #505050;
            font-size: 1.88vw;
            height: 14.58vw;
        }

        .textarea::placeholder {
            font-size: 1.88vw;
        }

        .textarea-error {
            margin-bottom: 0.52vw;
        }

        .textarea-error-text {
            font-size: 1.67vw;
            padding-left: 1.25vw;
        }
    }
}

@media (max-width: 425px) {
    .textarea-wrapper {
        .textarea {
            padding: 3.75vw 6.44vw;
            border: 0.21vw solid #505050;
            font-size: 3.75vw;
            height: 29.16vw;
        }

        .textarea::placeholder {
            font-size: 3.75vw;
        }

        .textarea-error {
            margin-bottom: 1.04vw;
        }

        .textarea-error-text {
            font-size: 3.33vw;
            padding-left: 2.5vw;
        }
    }
}
