.payment-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vw 0;
    gap: 5vw;

    .payment-title{
        font-size: 2.5vw;
        font-weight: 700;
        padding:  0 10vw;
        text-align: center;
    }

    .basic-info-container{
        width: 85vw;
        display: flex;
        justify-content: space-between;
        padding: 3vw;
        gap: 3vw;
        background-color: #fce500;
        .basic-info-text-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1vw;

            .basic-info-title{
                font-size: 0.9vw;
                font-weight: 700;
            }
            .basic-info-text{
                font-size: 0.9vw;
            }
        }
    }
    .payment-item-list{
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        // gap: 4vw;
        width: 85vw;
    }

}

@media(max-width: 768px){
    .payment-wrapper{
        padding: 7.5vw 0;
        gap: 7.5vw;
    
        .payment-title{
            font-size: 3.75vw;
        }
        .basic-info-container{
            width: 85vw;
            padding: 4.5vw;
            gap: 4.5vw;
            .basic-info-text-container{
                gap: 1.5vw;
    
                .basic-info-title{
                    font-size: 1.5vw;
                    font-weight: 700;
                }
                .basic-info-text{
                    font-size: 1.5vw;
                }
            }
        }
        .payment-item-list{
            gap: 6vw;
            width: 85vw;
        }
        
    }
}

@media(max-width: 425px){
    .payment-wrapper{
        padding: 10vw 0;
        gap: 10vw;
    
        .payment-title{
            font-size: 7.5vw;
        }
        .basic-info-container{
            width: 90vw;
            flex-direction: column-reverse;
            padding: 9vw;
            gap: 9vw;
            .basic-info-text-container{
                gap: 3vw;
    
                .basic-info-title{
                    font-size: 3vw;
                }
                .basic-info-text{
                    font-size: 3vw;
                }
            }
        }
        .payment-item-list{
            gap: 12vw;
            width: 90vw;
        }

    }
}