.language-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.04vw;

    p {
        font-size: 1.94vw;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        transition: all ease 0.3s;
        color: #000;
        cursor: pointer;

        &::after {
            content: "";
            width: 0;
            height: 0.14vw;
            background: #000;
            transition: all ease 0.3s;
        }

        &.active {
            &::after {
                width: 100%;
            }
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    .language-selector {
        gap: 1.56vw;

        p {
            font-size: 2.91vw;

            &::after {
                height: 0.21vw;
            }
        }
    }
}

@media (max-width: 425px) {
    .language-selector {
        gap: 3.13vw;

        p {
            font-size: 5.83vw;

            &::after {
                height: 0.42vw;
            }
        }
    }
}