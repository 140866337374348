.admin-panel-wrapper{
    display: flex;
    justify-content: center;

    .admin-panel-container{
        padding: 4.1vw 0 4.1vw;

        display: flex;
        flex-direction: column;
        gap: 2vw;

        .admin-panel-title{
            font-size: 5vw;
            color: #505050;
            font-weight: 700;
            margin-bottom: 2vw;
        }

        .admin-panel-application-list{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1vw;

            .admin-panel-application-container{
                width: 80vw;

                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1.4vw;
                padding: 2vw 3vw;
                background-color: var(--main-light);
                border: 1px solid rgba(18, 18, 18, 0.2);
                transition: all 0.4s ease;
                cursor: pointer;

                .admin-panel-application-text{
                    word-wrap: break-word; 
                    overflow-wrap: break-word;
                    width: 25%;
                }

                .admin-panel-application-button{
                    background-color: #fce500;
                    color: #121212;
                    border: 1px solid transparent;
                    transition: all 0.4s ease;
                    border-radius: 4vw;
                    padding: 0.8vw 1.6vw;
                    cursor: pointer;
                    text-wrap: nowrap;
                }
                .admin-panel-application-button:hover{
                    background-color: #121212;
                    color: #fce500;
                    border-color: #fce500;
                }
            }
            .admin-panel-application-container:hover{
                box-shadow: 0px 0px 2vw #dedede;
            }
        }

        .admin-pagination-container{
            max-width: 80%;
            margin: 0 auto;
            display: flex;
            gap: 1vw;

            .admin-pagination-button:first-child{
                margin-right: 2vw;
                svg{
                    transform: rotate(-180deg);
                }
            }

            .admin-pagination-button:last-child{
                margin-left: 2vw;
            }
        }
    }
}
@media(max-width: 768px){
    .admin-panel-wrapper{
        .admin-panel-container{
            padding: 6vw 0 6vw;
            gap: 3vw;
    
            .admin-panel-title{
                font-size: 7.5vw;
                margin-bottom: 3vw;
            }
    
            .admin-panel-application-list{
                gap: 1.5vw;
    
                .admin-panel-application-container{
                    width: 85vw;
                    gap: 2.1vw;
                    padding: 3vw 4.5vw;
    
                    .admin-panel-application-text{
                        word-wrap: break-word; 
                        overflow-wrap: break-word;
                        width: 25%;
                    }
    
                    .admin-panel-application-button{
                        border-radius: 6vw;
                        padding: 1.2vw 2.4vw;
                    }
                }
                .admin-panel-application-container:hover{
                    box-shadow: 0px 0px 3vw #dedede;
                }
            }
        }
    }
}

@media(max-width: 425px){
    .admin-panel-wrapper{
        .admin-panel-container{
            padding: 12vw 0 12vw;
            gap: 6vw;
    
            .admin-panel-title{
                font-size: 15vw;
                margin-bottom: 6vw;
            }
    
            .admin-panel-application-list{
                gap: 3vw;
    
                .admin-panel-application-container{
                    width: calc(90vw - 18vw);
                    gap: 4.2vw;
                    padding: 6vw 9vw;
                    flex-direction: column;
                    .admin-panel-application-text{
                        word-wrap: break-word; 
                        overflow-wrap: break-word;
                        width: 100%;
                    }
    
                    .admin-panel-application-button{
                        border-radius: 12vw;
                        padding: 2.4vw 4.8vw;
                    }
                }
                .admin-panel-application-container:hover{
                    box-shadow: 0px 0px 6vw #dedede;
                }
            }
        }
    }
}