*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    width: 100%;
    box-sizing: border-box;
    max-width: 91.04vw;
    padding: 0 1.04vw;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .container {
        max-width: 95vw;
        padding: 0 2vw;
    }
}

@media (max-width: 425px) {
    .container {
        max-width: 100%;
        padding: 0 4vw;
    }
}
