.form-section-wrapper {
    background-color: #121212;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-section-container {
        padding: 4.17vw 0;
        display: flex;
        justify-content: space-between;
        gap: 6vw;

        .form-section-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 2.08vw 0;
            width: 30%;

            .form-section-info-container {
                display: flex;
                flex-direction: column;
                position: relative;

                .form-section-info-title {
                    color: #fff;
                    font-weight: 700;
                    font-size: 5vw;
                }

                .form-section-info-subtitle {
                    color: #fff;
                    font-weight: 500;
                    font-size: 2.5vw;
                }

                &::before {
                    position: absolute;
                    content: "";
                    background-color: #fce500;
                    width: 1.25vw;
                    height: 1.25vw;
                    border-radius: 50%;
                    top: 50%;
                    left: -5vw;
                    transform: translateY(-50%);
                }
            }
        }

        .form-section {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding: 4.86vw 4.51vw;
            border: 0.07vw solid #505050;
            gap: 1vw;

            .form-section-title {
                color: #fce500;
                font-size: 2.5vw;
                font-weight: 700;
            }

            .form-section-subtitle {
                color: #fce500;
                font-size: 1.67vw;
                font-weight: 400;
                margin-bottom: 3.47vw;
            }

            .textarea-wrapper {
                margin: 3.47vw 0;
            }

            .form-section-text {
                font-size: 0.97vw;
                color: #fff;
                margin-top: 1.39vw;
            }

            .form-section-button {
                background-color: #fce500;
                color: #000;
                border: none;
                border-radius: 30px;
                font-weight: bold;
                font-size: 1.67vw;
                min-width: 16.25vw;
                width: fit-content;
                padding: 1.04vw 1.39vw;
                cursor: pointer;
                transition: all 0.4s ease;
                text-align: center;

                &:hover {
                    background-color: #000;
                    color: #fce500;
                    border: 0.14vw solid #fce500;
                }

                &:focus {
                    outline: none;
                }
            }

            input, textarea{
                color: #fce500;
            }
        }
    }
}


@media (max-width: 768px) {
    .form-section-wrapper {
        .form-section-container {

            flex-direction: column;
            align-items: center;
            padding: 6.26vw 0;
            gap: 9vw;

            .form-section-info {
                width: 80%;
                padding: 3.13vw 0;
                .form-section-info-container {
                    .form-section-info-title {
                        font-size: 7.5vw;
                    }

                    .form-section-info-subtitle {
                        font-size: 3.75vw;
                    }

                    &::before {
                        width: 1.88vw;
                        height: 1.88vw;
                    }
                }
            }

            .form-section {
                width: 80%;
                padding: 7.29vw 6.77vw;
                gap: 1.5vw;

                .form-section-title {
                    font-size: 3.75vw;
                }

                .form-section-subtitle {
                    font-size: 2.5vw;
                    margin-bottom: 5.2vw;
                }

                .textarea-wrapper {
                    margin: 5.2vw 0;
                }

                .form-section-text {
                    font-size: 1.46vw;
                    margin-top: 2.09vw;
                }

                .form-section-button {
                    font-size: 2.5vw;
                    min-width: 24.38vw;
                    padding: 1.56vw 2.09vw;
                    border-radius: 4.17vw;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .form-section-wrapper {
        .form-section-container {
            padding: 12.53vw 0;
            gap: 18vw;

            .form-section-info {
                padding: 6.26vw 0;
                .form-section-info-container {
                    .form-section-info-title {
                        font-size: 15vw;
                    }

                    .form-section-info-subtitle {
                        font-size: 7.5vw;
                    }

                    &::before {
                        width: 3.75vw;
                        height: 3.75vw;
                    }
                }
            }

            .form-section {
                gap: 3vw;

                .form-section-title {
                    font-size: 7.5vw;
                }

                .form-section-subtitle {
                    font-size: 5vw;
                    margin-bottom: 10.42vw;
                }

                .textarea-wrapper {
                    margin: 10.42vw 0;
                }

                .form-section-text {
                    font-size: 2.92vw;
                    margin-top: 4.17vw;
                }

                .form-section-button {
                    font-size: 5vw;
                    min-width: 48.75vw;
                    padding: 3.13vw 4.17vw;
                    border-radius: 8.33vw;
                }
            }
        }
    }
}

@media(max-width: 500px){
    .form-section-wrapper{
        .form-section-container{
            flex-direction: column;
            .form-section{
                padding: 70px 30px;
                width: calc(100% - 60px);
                .textarea-wrapper{
                    .textarea{
                        padding: 16px 20px;
                        width: calc(100% - 40px);
                    }
                }
                .form-section-button{
                    min-width: 0;
                }
            }
            .form-section-info{
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 20px;
                
            }
        }
    }
}