.footer-wrapper {
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-links-title {
        font-size: 1.66vw;
        font-weight: 700;
        color: #fff;
        padding-bottom: 1vw;
    }

    a {
        font-size: 1.25vw;
        color: #959595;
        transition: all ease 0.3s;
        display: flex;
        flex-direction: column;

        &::after {
            content: "";
            width: 0;
            height: 0.1vw;
            background: #959595;
            transition: all ease 0.3s;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }
    }

    p {
        font-size: 1.25vw;
        color: #959595;
    }

    .footer-info {
        width: 84.7vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3.13vw 0;
    }

    .footer-container {
        width: 84.7vw;
        padding: 3.13vw 0 4.17vw;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.07vw solid #959595;
        flex-wrap: wrap;
        gap: 3vw;

        .footer-logo {
            color: #fff;
            font-size: 3.47vw;
            font-weight: 500;
        }

        .footer-links-wrapper {
            display: flex;
            gap: 5vw;
            flex-wrap: wrap;

            .footer-links-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

@media (max-width: 768px) {
    .footer-wrapper {
        .footer-links-title {
            font-size: 2.5vw;
        }

        a {
            font-size: 1.875vw;
        }

        p {
            font-size: 1.875vw;
        }

        .footer-info {
            padding: 4.69vw 0;
        }

        .footer-container {
            padding: 4.69vw 0 6.26vw;

            .footer-logo {
                font-size: 5.21vw;
            }

            .footer-links-wrapper {
                gap: 7.5vw;
            }
        }
    }
}

@media (max-width: 425px) {
    .footer-wrapper {
        .footer-links-title {
            font-size: 5vw;
        }

        a {
            font-size: 3.75vw;
        }

        p {
            font-size: 3.75vw;
        }

        .footer-info {
            padding: 9.38vw 0;
        }

        .footer-container {
            padding: 9.38vw 0 12.5vw;

            .footer-logo {
                font-size: 10.42vw;
            }

            .footer-links-wrapper {
                gap: 10vw;
                flex-direction: column;

            }
        }
    }
}
