.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.67vw; // 1vw / 1.5
  }
  
  .checkbox {
    width: 1.33vw; // 2vw / 1.5
    height: 1.33vw; // 2vw / 1.5
    border: 1px solid #121212;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.33vw; // 0.5vw / 1.5
    transition: background 0.2s, border-color 0.2s;
    font-size: 0.67vw; // 1vw / 1.5
  
    &.checked {
      background: #fce500;
      border-color: #121212;
      color: #121212;
    }
  }
  
  .checkmark {
    width: 70%;
    height: 70%; // 70% / 1.5
    background: #121212;
    border-radius: 0.23vw; // 0.35vw / 1.5
  }
  
  .checkbox-label {
    font-size: 1.2vw;
    color: #121212;
  }
  
  /* Adaptive for 768px (multiply all values by 1.5) */
  @media (max-width: 768px) {
    .checkbox-container {
      gap: 1vw; // 0.67vw * 1.5
    }
  
    .checkbox {
      width: 2vw; // 1.33vw * 1.5
      height: 2vw;
      font-size: 1vw;
      border-radius: 0.5vw; // 0.33vw * 1.5
    }
  
    .checkmark {
      width: 70%; // 46.67% * 1.5
      height: 70%;
      border-radius: 0.35vw; // 0.23vw * 1.5
    }
  
    .checkbox-label {
      font-size: 1.8vw;
    }
  }
  
  /* Adaptive for 425px (multiply all values by 3) */
  @media (max-width: 425px) {
    .checkbox-container {
      gap: 2vw; // 0.67vw * 3
    }
  
    .checkbox {
      width: 4vw; // 1.33vw * 3
      height: 4vw;
      font-size: 2vw;
      border-radius: 1vw; // 0.33vw * 3
    }
  
    .checkmark {
      width: 140%; // 46.67% * 3 (capped at 100% if needed)
      height: 140%;
      border-radius: 0.7vw; // 0.23vw * 3
    }
  
    .checkbox-label {
      font-size: 3.2vw
    }
  }
  