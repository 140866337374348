.banner-slider-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 2.78vw;


    .banner-slider-item-container {
        display: flex;
        gap: 2.43vw;

        .banner-slider-item {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            width: 38.89vw;
            background: #fce500;
            position: relative;
            transition: all ease 0.3s;

            .banner-slider-item-title {
                max-width: 100%;
                width: fit-content;
                padding: 0.35vw;
                font-weight: 900;
                font-size: 1.67vw;
                background: #000;
                color: #fce500;
                position: absolute;
                z-index: 1;
            }


            .banner-slider-item-container {
                display: flex;
                gap: 4.17vw;
                box-shadow: 0px 0.35vw 0.69vw rgba(188, 113, 0, 0.4);

                .banner-slider-item-img {
                    img {
                        width: 17.36vw;
                        height: 10.42vw;
                    }
                }

                .banner-slider-item-content {
                    width: 17.36vw;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    padding: 10px 0 10px 0;

                    p {
                        font-weight: 700;
                        font-size: 1.25vw;
                        padding-left: 1.11vw;
                        line-height: 1.39vw;
                    }

                    .banner-slider-content-arrow {
                        border-bottom: 0.21vw solid #000;
                        border-right: 0.21vw solid #000;
                        width: 0.69vw;
                        height: 0.69vw;
                        transform: rotate(-45deg);
                        margin-right: 1.74vw;
                    }
                }
            }
        }
    }

    .banner-slider-page-container {
        display: flex;
        gap: 0.56vw;
        align-items: center;
        justify-content: center;

        .banner-slider-page-item {
            width: 0.9vw;
            height: 0.9vw;
            border-radius: 50%;
            border: 0.07vw solid #000;
            transition: all ease 0.3s;
            cursor: pointer;

            &.active {
                background: #000;
            }
        }
    }
}

@media (max-width: 768px) {
    .banner-slider-container {
        gap: 4.17vw;

        .banner-slider-item-container {
            gap: 3.65vw;

            .banner-slider-item {
                width: 58.33vw;

                .banner-slider-item-title {
                    padding: 0.52vw;
                    font-size: 2.5vw;
                }

                .banner-slider-item-container {
                    gap: 6.25vw;

                    .banner-slider-item-img {
                        img {
                            width: 26.04vw;
                            height: 15.63vw;
                        }
                    }

                    .banner-slider-item-content {
                        width: 26.04vw;

                        p {
                            font-size: 1.88vw;
                            padding-left: 1.67vw;
                            line-height: 2.08vw;
                        }

                        .banner-slider-content-arrow {
                            border-bottom: 0.31vw solid #000;
                            border-right: 0.31vw solid #000;
                            width: 1.04vw;
                            height: 1.04vw;
                            margin-right: 2.6vw;
                        }
                    }
                }
            }
        }

        .banner-slider-page-container {
            gap: 0.83vw;

            .banner-slider-page-item {
                width: 1.35vw;
                height: 1.35vw;
                border: 0.1vw solid #000;
            }
        }
    }
}

@media (max-width: 425px) {
    .banner-slider-container {
        gap: 8.33vw;

        .banner-slider-item-container {
            gap: 7.29vw;

            .banner-slider-item {
                width: 100%;

                .banner-slider-item-title {
                    padding: 1.04vw;
                    font-size: 5vw;
                }

                .banner-slider-item-container {
                    gap: 12.5vw;
                    flex-direction: column;

                    .banner-slider-item-img {
                        img {
                            width: 52.08vw;
                            height: 31.25vw;
                        }
                    }

                    .banner-slider-item-content {
                        width: 52.08vw;
                        padding-bottom: 3.33vw;

                        p {
                            font-size: 3.75vw;
                            padding-left: 3.33vw;
                            line-height: 4.17vw;
                        }

                        .banner-slider-content-arrow {
                            border-bottom: 0.63vw solid #000;
                            border-right: 0.63vw solid #000;
                            width: 2.08vw;
                            height: 2.08vw;
                            margin-right: 5.21vw;
                        }
                    }
                }
            }
        }

        .banner-slider-page-container {
            gap: 1.67vw;

            .banner-slider-page-item {
                width: 2.7vw;
                height: 2.7vw;
                border: 0.21vw solid #000;
            }
        }
    }
}
