
.services-offer-item-container {
    padding: 2.29vw 3.4vw;
    background-color: var(--main-light);
    border: 0.07vw solid rgba(18, 18, 18, 0.2);
    display: flex;
    flex-direction: column;
    position: relative;
    transition: all 0.4s ease;
    cursor: pointer;

    img {
        width: 100%;
        height: 13.89vw;
        object-fit: contain;
        margin-bottom: 2.08vw;
    }

    .services-offer-item-title {
        font-size: 1.25vw;
        font-weight: 700;
        margin-bottom: 1.6vw;
        min-height: 2.78vw;
    }

    .services-offer-item-text {
        color: #565656;
        padding-bottom: 0.69vw;
        font-size: 1.25vw;
    }

    .services-offer-item-action-container {
        padding-top: 1.39vw;
        border-top: 0.07vw solid rgba(18, 18, 18, 0.2);
        display: flex;
        align-items: center;
        margin: auto 0 0;
        justify-content: space-between;
        gap: 0.69vw;

        .services-offer-item-price {
            font-size: 0.83vw;
            font-weight: 300;

            span {
                font-size: 1.67vw;
                color: #121212;
            }
        }

        .button{
            width: fit-content;
            padding: 0.4vw 1.2vw;
            p{
                font-size: 1.25vw;
            }
        }
    }
}

@media (max-width: 768px) {
    
    .services-offer-item-container {
        padding: 3.44vw 5.1vw;

        img {
            height: 20.84vw;
            margin-bottom: 3.12vw;
        }

        .services-offer-item-title {
            font-size: 1.87vw;
            margin-bottom: 2.4vw;
        }

        .services-offer-item-text {
            font-size: 1.87vw;
            padding-bottom: 1.04vw;
        }

        .services-offer-item-action-container {
            padding-top: 2.08vw;
            gap: 1.04vw;

            .services-offer-item-price {
                font-size: 1.25vw;

                span {
                    font-size: 2.51vw;
                }
            }

            .button{
                width: fit-content;
                padding: 0.6vw 1.8vw;
                p{
                    font-size: 1.87vw;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .services-offer-item-container {
        padding: 6.88vw 10.2vw;

        img {
            height: 41.67vw;
            margin-bottom: 6.25vw;
        }

        .services-offer-item-title {
            font-size: 3.75vw;
            margin-bottom: 4.8vw;
        }

        .services-offer-item-text {
            font-size: 3.75vw;
            padding-bottom: 2.08vw;
        }

        .services-offer-item-action-container {
            padding-top: 4.17vw;
            gap: 2.08vw;

            .services-offer-item-price {
                font-size: 2.5vw;

                span {
                    font-size: 5.02vw;
                }
            }

            .button{
                width: fit-content;
                padding: 1.2vw 3.6vw;
                p{
                    
                    font-size: 3.75vw;
                }
            }
        }
    }

}
