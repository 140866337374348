.admin-pagination-button{
    background: #fce500;
    padding: 0.5vw;
    border-radius: 0.5vw;
    width: 3vw;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.4s;

    &:hover{
        transform: scale(1.1);
    
        background: #121212;
        border: 1px solid #fce500;

        p{
            color: #fce500;
        }

        svg{
            fill: #fce500;
        }
    }

    p{
        font-size: 0.97vw;
        color: #121212;
    }

    svg{
        fill: #121212;
        width: 2vw;
    }

    &.active{
        background: #121212;
        border: 1px solid #fce500;

        p{
            color: #fce500;
        }
    }
}