.header {
    width: 100%;
    padding: 2.92vw 0 2.22vw;
    border-bottom: 0.07vw solid #e4e4e4;
    position: fixed;
    z-index: 100;
    background: rgba(255, 255, 255, 1);

    .header-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1.04vw;

        .header-contact {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .header-contact-phone-numbers {
                display: flex;
                align-items: center;
                gap: 1.04vw;

                a {
                    font-size: 1.39vw;
                    font-weight: 700;
                    color: #000;
                    text-decoration: none;
                }
            }
        }

        .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-content-logo {
                cursor: pointer;
                svg {
                    width: 14.86vw;
                }
            }

            .header-content-nav {
                display: flex;
                flex-direction: row;
                gap: 2.08vw;

                a {
                    display: flex;
                    flex-direction: column;
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 1.39vw;
                    transition: all ease 0.3s;
                    color: #000;

                    &::after {
                        content: "";
                        width: 0;
                        height: 0.14vw;
                        background: #000;
                        transition: all ease 0.3s;
                    }

                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .header {
        padding: 4.38vw 0 3.34vw;

        .header-container {
            gap: 1.56vw;

            .header-contact {
                .header-contact-phone-numbers {
                    gap: 1.56vw;

                    a {
                        font-size: 2.08vw;
                    }
                }
            }

            .header-content {
                .header-content-logo {
                    svg {
                        width: 22.29vw;
                    }
                }

                .header-content-nav {
                    gap: 3.13vw;

                    a {
                        font-size: 2.08vw;

                        &::after {
                            height: 0.21vw;
                        }
                    }
                }
            }
        }

    }
}

@media (max-width: 425px) {
    .header {
        padding: 8.75vw 0 6.67vw;

        .header-container {
            gap: 3.13vw;

            .header-contact {
                .header-contact-phone-numbers {
                    gap: 3.13vw;

                    a {
                        font-size: 4.17vw;
                    }
                }
            }

            .header-content {
                .header-content-logo {
                    svg {
                        width: 44.58vw;
                    }
                }

                .header-content-nav {
                    gap: 6.25vw;

                    a {
                        font-size: 4.17vw;

                        &::after {
                            height: 0.42vw;
                        }
                    }
                }
            }
        }
    }
}