.login-wrapper {
    display: flex;
    justify-content: center;


    .login-container {
        width: 84.7vw;
        padding: 4.17vw 0;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2vw;

        .input-wrapper {
            width: 50%;
        }


        .login-title {
            font-size: 5vw;
            color: #505050;
            font-weight: 700;
            margin-bottom: 2.08vw;
        }


        .login-subtitle {
            font-size: 2.5vw;
            color: #505050;
            font-weight: 700;
        }
        .button{
            width: fit-content;
        }
    }
}

@media(max-width: 768px){
    .login-wrapper{
        padding: 6.25vw;

        .login-container{
            width: 100%;
            gap: 3vw;

            .input-wrapper{
                width: 100%;
            }

            .login-title{
                font-size: 7.5vw;
                margin-bottom: 3.12vw;
                
            }

            .login-subtitle{
                font-size: 3.75vw;
            }
        }
    }
}

@media(max-width: 425px){
    .login-wrapper{
        padding: 12.5vw;

        .login-container{
            width: 100%;
            gap: 6vw;

            .login-title{
                font-size: 15vw;
                margin-bottom: 6vw;
            }

            .login-subtitle{
                font-size: 7.5vw;
            }
        }
    }
}
