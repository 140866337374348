.layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    .layout-content {
        position: relative;
    }
}
