.feedback-modal-wrapper{
    padding: 1.11vw;
    border-radius: 1.39vw;
    transition: all 0.4s ease;
    width: 80vw;
    margin: 0 auto;
    height: min-content;
    position: relative;
    background: #fff;
    box-shadow: 0 0 0.49vw #121212;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .feedback-modal-form-container{
        padding: 4.86vw;
        width: 45%;

        display: flex;
        flex-direction: column;
        gap: 1.04vw;
        align-items: flex-start;

        .feedback-modal-form-title{
            margin-bottom: 2.08vw;
            font-weight: 700;
            font-size: 1.67vw;
        }

        .feedback-modal-form-subtitle{
            font-size: 1.25vw;
            line-height: 1.6vw;
            margin-bottom: 1.39vw;
        }

        .input-wrapper{
            width: 100%;
            margin-bottom: 1.39vw;
        }

        .button{
            width: fit-content;
        }

        input, textarea{
            color: #121212;
            font-size: 1.11vw;
        }
    }

    .feedback-modal-image-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 1.39vw;
        border-bottom-left-radius: 1.39vw;
        min-width: 31.25vw;
        padding: 4.86vw 1.39vw;

        .feedback-modal-title{
            font-weight: 700;
            font-size: 2.5vw;
        }

        .feedback-modal-phone{
            text-align: center;
            font-size: 0.97vw;
            margin-top: 2.08vw;

            span{
                font-weight: 700;
            }
        }
    }
}

@media (max-width: 768px) {
    .feedback-modal-wrapper{
        padding: 1.67vw;
        border-radius: 2.09vw;
        box-shadow: 0 0 0.74vw #121212;

        .feedback-modal-form-container{
            width: 100%;
            padding: 7.29vw;
            gap: 1.56vw;

            .feedback-modal-form-title{
                margin-bottom: 3.12vw;
                font-size: 2.51vw;
            }

            .feedback-modal-form-subtitle{
                font-size: 1.88vw;
                line-height: 2.4vw;
                margin-bottom: 2.09vw;
            }

            .input-wrapper{
                margin-bottom: 2.09vw;
            }



            input, textarea{
                font-size: 1.6vw;
            }

        }

        .feedback-modal-image-container{
            display: none;
        }
    }
}

@media (max-width: 425px) {
    .feedback-modal-wrapper{
        padding: 3.33vw;
        border-radius: 4.17vw;
        box-shadow: 0 0 1.47vw #121212;

        .feedback-modal-form-container{
            width: 100%;
            padding: 9vw;
            gap: 3.12vw;

            .feedback-modal-form-title{
                margin-bottom: 6.24vw;
                font-size: 5.01vw;
            }

            .feedback-modal-form-subtitle{
                font-size: 3.75vw;
                line-height: 4.8vw;
                margin-bottom: 4.17vw;
            }

            .input-wrapper{
                margin-bottom: 4.17vw;
            }

            input, textarea{
                color: #121212;
                font-size: 3.2vw;
            }
        }

        .feedback-modal-image-container{
            display: none;
        }
    }
}
