.contacts-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vw 0;
    gap: 5vw;

    .contacts-title{
        font-size: 2.5vw;
        font-weight: 700;
    }

    .image-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 85vw;
        border: 1px solid #cdcdcd ;
        gap: 3vw;
        padding: 0 1vw;

        .image-text-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2vw;
            .image-text{
                font-size: 1.6vw;
            }

            .button{
                width: fit-content;
                p{
                    font-size: 1vw;
                }
            }
        }
    }
    .contacts-container{
        width: 85vw;
        display: flex;
        justify-content: center;
        gap: 3vw;
        flex-wrap: wrap;
    }
}

@media(max-width: 768px){
    .contacts-wrapper{
        padding: 7.5vw 0;
        gap: 7.5vw;
    
        .contacts-title{
            font-size: 3.75vw;
        }
        .image-container{
            width: 90vw;
            gap: 4.5vw;
            padding: 0 2vw;
    
            .image-text-container{
                gap: 3vw;
                .image-text{
                    font-size: 2.4vw;
                }
    
                .button{
                    width: fit-content;
                    p{
                        font-size: 1.5vw;
                    }
                }
            }
        }
        .contacts-container{
            width: 90vw;
            gap: 4.5vw;
        }
    }
}

@media(max-width: 425px){
    .contacts-wrapper{
        padding: 10vw 0;
        gap: 10vw;
    
        .contacts-title{
            font-size: 7.5vw;
        }
        .image-container{
            flex-direction: column;
            align-items: center;
            gap: 9vw;
            padding: 3vw;
    
            .image-text-container{
                gap: 3vw;
                .image-text{
                    font-size: 4.8vw;
                }
    
                .button{
                    width: fit-content;
                    p{
                        font-size: 3vw;
                    }
                }
            }
        }
        .contacts-container{
            width: 90vw;
            gap: 9vw;
        }
    }
}