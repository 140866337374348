.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    height: 3.33vw;
    padding: 0.56vw 1.6vw;
    background-color: #fce500;
    border: 0.07vw solid var(--main-dark);
    border-radius: 5.56vw;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s ease;

    p {
        font-weight: 700;
        font-size: 1.67vw;
        color: #000;
    }

    &:hover {
        background-color: #000;

        p {
            color: #fce500;
        }
    }

    &.inverse {
        background-color: transparent;
        border: 0.07vw solid #fce500;
        color: #000;

        &:hover {
            background-color: #fce500;

            p {
                color: #000;
            }
        }
    }
}

@media (max-width: 768px) {
    .button {
        height: 5vw;
        padding: 0.84vw 2.4vw;
        border-radius: 8.34vw;

        p {
            font-size: 2.5vw;
        }
    }
}

@media (max-width: 425px) {
    .button {
        height: 10vw;
        padding: 1.68vw 4.8vw;
        border-radius: 16.68vw;

        p {
            font-size: 5vw;
        }
    }
}
