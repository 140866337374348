.input {
    outline: none;
    width: 100%;
    padding: 0.56vw 0;
    background: transparent;
    border: none;
    border-bottom: 0.07vw solid #505050;
    color: #505050;
    font-size: 1.11vw;
}

.input-error {
    margin-bottom: 0.35vw;
}

.input-error-text {
    font-size: 1.11vw;
    color: red;
}

@media (max-width: 768px) {
    .input {
        padding: 0.84vw 0;
        border-bottom: 0.1vw solid #505050;
        font-size: 1.67vw;
    }

    .input-error {
        margin-bottom: 0.52vw;
    }

    .input-error-text {
        font-size: 1.67vw;
    }
}

@media (max-width: 425px) {
    .input {
        padding: 1.68vw 0;
        border-bottom: 0.21vw solid #505050;
        font-size: 3.33vw;
    }

    .input-error {
        margin-bottom: 1.04vw;
    }

    .input-error-text {
        font-size: 3.33vw;
    }
}
