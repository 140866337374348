.modal{
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 101;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(3px);
    transition: all 0.4s ease;
}

.show{
    pointer-events: auto;
    transition: all 0.4s ease;
    opacity: 1;
}