.contact-item-container{
    width: calc(35% - 3.5vw);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5vw;
    padding: 1vw 2vw;
    border-left: 1px solid #cdcdcd ;
    

    .contact-item-title{
        font-weight: 700;
        font-size: 1.1vw;
    }

    .contact-item-subtitle{
        font-weight: 700;
        font-size: 1vw;
    }

    .contact-item-text{
        font-size: 1vw;
    }
}
@media(max-width: 768px){
    .contact-item-container{
        width: calc(50% - 2.3vw);
        gap: 2.3vw;
        padding: 1.5vw 3vw;

        .contact-item-title{
            font-size: 1.65vw;
        }

        .contact-item-subtitle{
            font-size: 1.5vw;
        }

        .contact-item-text{
            font-size: 1.5vw;
        }
    }
}
    

@media(max-width: 425px){
    .contact-item-container{
        width: 100%;
        gap: 2.3vw;
        padding: 3vw 6vw;

        .contact-item-title{
            font-size: 3.3vw;
        }

        .contact-item-subtitle{
            font-size: 3vw;
        }

        .contact-item-text{
            font-size: 3vw;
        }
    }
}